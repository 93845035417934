import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';

@Component({
  selector: 'app-dim-input-text',
  templateUrl: './dim-input-text.component.html',
  styleUrls: ['./dim-input-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DimInputTextComponent),
      multi: true
    }
  ]
})
export class DimInputTextComponent implements ControlValueAccessor, OnChanges, OnInit {
  @Input() id = 'campo_de_texto';
  @Input() label = 'Campo de texto';
  @Input() required = false;
  @Input() maxLength = 100;
  @Input() placeholder = 'Digite';
  @Input() icon: string = null;
  @Input() readonly = false;
  @Input() maxValue: number;
  @Input() showErrors = false;
  @Input() showErrorsMessage = 'A quantidade a devolver deve ser menor que o saldo.';
  @Input() showErrorsAsNgbTooltip = false;
  // Configura o valor default do formControl caso o valor do input seja null ou undefined antes de emitir o onChange
  @Input() defaultValue: string | number = null;
  @Input() validate = true;

  // Modifica o input para usar currencyMask inputmode="numeric". Obs: Não é possível utilizar somente 'mask' como @Input pois causará conflito com a lib 'uiowa/digit-only'.
  @Input() maskType: 'currency' | 'quantity' = null;
  // Propriedades individuais para simplificar a customização da máscara.
  @Input() maskPrefix = '';
  @Input() maskThousands = '.';
  @Input() maskDecimal = ',';
  @Input() maskAlign = 'right';
  @Input() maskPrecision = 2;
  @Input() maskSuffix = '';
  @Input() maskAllowNegative = true;

  control = new FormControl<string | number>('', [this.validateControl()]);

  @Output() validated = new EventEmitter<boolean>();

  // Se houverem opções, ativa o elemento no template e classes do arquivo .scss entram em vigor pra simular o visual two-in-one.
  @Input() dropdownOptions: DropdownOption[];
  @Input() dropdownSelected: DropdownOption;
  @Output() dropdownChanged = new EventEmitter();

  ngOnChanges(changes: SimpleChanges) {
    if (changes && (changes.required || changes.readonly || changes.maxValue || changes.validate)) {
      this.control.updateValueAndValidity();
    }
  }

  ngOnInit() {
    if (this.dropdownOptions?.length > 0 ) {
      this.dropdownSelected = this.dropdownOptions[0];
    }
  }

  get currencyMaskConfig(): NgxCurrencyConfig {
    return {
      align: this.maskAlign,
      allowNegative: this.maskAllowNegative,
      allowZero: true,
      decimal: this.maskDecimal,
      precision: this.maskPrecision,
      prefix: this.maskPrefix,
      suffix: this.maskSuffix,
      thousands: this.maskThousands,
      nullable: true,
      min: null,
      max: null,
      inputMode: NgxCurrencyInputMode.Financial,
    };
  }

  onChange: (value: string | number) => void;
  onTouched: () => void;

  writeValue(value: string | number): void {
    this.control.setValue(value);
  }

  registerOnChange(fn: (value: string | number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onInputTextChange(): void {
    if (![null, undefined].includes(this.defaultValue) && [null, undefined].includes(this.control.value)) {
      this.control.setValue(this.defaultValue);
    }
    this.onChange(this.control.value);
    this.validated.emit(this.control.valid);
    this.onTouched();
  }

  inputTextNgStyle() {
    const style: InputTextNgStyle = {};
    if (this.icon) {
      style['padding-left'] = '30px';
    }
    return style;
  }

  getNgClass() {
    return {
      'has-error': this.control.invalid,
      'has-dropdown': this.dropdownOptions?.length > 0
    };
  }

  getNgbTooltip() {
    if (this.showErrorsAsNgbTooltip && this.control.invalid) {
      if (this.control.errors) {
        return this.control.errors.custom?.message;
      }
      return 'Campo inválido';
    }

    return '';
  }

  handleDropdownChange(option: DropdownOption) {
    this.dropdownSelected = option;
    this.dropdownChanged.emit(this.dropdownSelected);
  }

  private validateControl(): ValidatorFn {
    return (c: FormControl): ValidationErrors => {
      if (!this.validate || this.readonly) {
        return null;
      }

      if (this.maskType && this.maxValue && c.value > this.maxValue) {
        return { custom: { message: this.showErrorsMessage } };
      }

      if (this.required && !c.value) {
        return { custom: { message: 'Campo obrigatório.' } };
      }

      return null;
    };
  }
}

interface InputTextNgStyle {
  'padding-left'?: string;
}

export interface DropdownOption {
  placeholder: string;
  label: string;
  value: string | number;
}
