import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs';
import { StorageFunctions } from '../../../../general/storage';
import { markFormGroupTouched, setFormValue } from '../../../../general/utils';
import { UtilFunctions } from '../../../../general/utils-service';
import { CidadesService } from '../../../../services/cidades.service';
import { EstadosService } from '../../../../services/estados.service';
import { MessageService } from '../../../../services/message.service';
import { MotoristasService } from '../../../../services/motoristas.service';
import { CadastroPadraoComponent } from '../../../../shared/cadastro-padrao/cadastro-padrao.component';
import swal from 'sweetalert2';

@Component({
  selector: 'app-motorista-cadastro-form',
  templateUrl: './motorista-cadastro-form.component.html',
  styleUrls: ['./motorista-cadastro-form.component.scss']
})
export class MotoristaCadastroFormComponent extends CadastroPadraoComponent implements OnInit {
  @Input() modal: boolean = false;
  @Output() salvar = new EventEmitter<number>();
  @Output() cancelar = new EventEmitter();
  @Output() naoEncontrado = new EventEmitter();

  cidadesMap = [];
  estadosMap = [];

  constructor(
    private estadoService: EstadosService,
    private cidadeService: CidadesService,
    route: ActivatedRoute,
    utilFunctions: UtilFunctions,
    message: MessageService,
    storage: StorageFunctions,
    location: Location,
    private service: MotoristasService
  ) {
    super(route, utilFunctions, message, storage, location);

    this.form = new FormGroup({
      id: new FormControl(null),
      nome: new FormControl(null, Validators.required),
      apelido: new FormControl(null),
      cpf: new FormControl(null, Validators.required),
      endereco: new FormControl(null),
      cidadeId: new FormControl(null, Validators.required),
      estadoId: new FormControl(null, Validators.required),
      fornecedorID: new FormControl(null),
      veiculoPadraoID: new FormControl(null),
      cep: new FormControl(null, [Validators.minLength(8)]),
      bairro: new FormControl(null),
      rg: new FormControl(null),
      telefone: new FormControl(null, [Validators.minLength(10)]),
      contato: new FormControl(null),
      celular: new FormControl(null, [Validators.minLength(11)]),
      email: new FormControl(null),
      codigoPIS: new FormControl(null),
      observacoes: new FormControl(null),
      dataAniversario: new FormControl(null),
      inativo: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.carregarEstados();

    this.form.get('estadoId').valueChanges.subscribe((value) => {
      this.onChangeEstado(value);
    });

    this.estadoService.get().subscribe((res) => {
      this.estadosMap = res;
    });
  }

  public carregarMotorista(id: number) {
    this.alteraLoading(true);

    this.service.getById(id)
      .pipe(finalize(() => this.alteraLoading(false)))
      .subscribe(res => {
        if (res?.data === null) {
          this.messageService.error('Motorista não encontrado.', 5000);
          return;
        }
        setFormValue(this.form, res);
        this.form.controls['id'].setValue(id);

        if (res.cidade != null) {
          this.form.controls['estadoId'].setValue(res.cidade.estadoId);
          this.getCidade(res.cidade.estadoId);
          this.form.controls['cidadeId'].setValue(res.cidade.id);
        }
      })
  }

  submit() {
    markFormGroupTouched(this.form);
    if (!this.form.valid) {
      return;
    }

    this.loading['salvando'] = true;
    this.service.salvar(this.form.value)
      .pipe(finalize(() => {
        this.loading['salvando'] = false;
      }))
      .subscribe(res => {
        this.messageService.success('Motorista salvo com sucesso!');
        this.salvar.emit(res.generatedId);
      }, error => {
        this.messageService.error('Ocorreu um erro ao salvar o registro.');
      })
  }

  carregarEstados() {
    this.estadoService.get()
      .subscribe(res => {
        this.estadosMap = res;
      });
  }

  validaForm() {
    markFormGroupTouched(this.form);
  }

  get appendTo() {
    return this.modal ? '#modal-motorista' : null;
  }
  
  getCidade(estadoId) {
    this.cidadeService.getByEstado(estadoId).subscribe((result) => {
      this.cidadesMap = result;
    });
  }

  onChangeEstado(estadoId: any) {
    this.form.controls['cidadeId'].setValue(null);
    this.getCidade(estadoId);
  }

  definirNome(nome: string) {
    this.form.get('nome').setValue(nome);
  }

  excluir() {
    if (!this.form.get('id').value) {
      return;
    }

    swal({
      ...this.mensagemExclusao(),
      preConfirm: () => {
        return new Promise((resolve, reject) => {
          this.service.delete(this.form.get('id').value)
            .subscribe({
              next: () => {
                resolve({});
                this.operacaoRealizada();
              },
              error: (error) => {
                reject(error);
              }
            });
        })
          .catch((error) => {
            swal.showValidationError(`${error.msg.errors[0]}`);
          });
      },
      allowOutsideClick: () => !swal.isLoading()
    });
  }
}
