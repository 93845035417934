import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ManifestoTransporteService extends BaseService {
  private emailMdfe = new Subject<{ mdfeId: number }>();
  solicitacaoAbrirEnvioEmail = this.emailMdfe.asObservable();
  
  constructor(private http: HttpClient) { super(); }

  consultar(filtros) {
    return this.http.post(this.UrlService + 'ManifestoTransporte/Consultar', filtros)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  salvar(dados): Observable<number> {
    return this.http.post(this.UrlService + 'ManifestoTransporte/Salvar', dados)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  salvarRascunho(dados): Observable<number> {
    return this.http.post(this.UrlService + 'ManifestoTransporte/SalvarRascunho', dados)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  enviar(mdfeId: number): Observable<{ mdfeId: number, mdfeNum: number }> {
    return this.http.post(this.UrlService + 'ManifestoTransporte/Enviar', mdfeId)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  buscarDadosMdfe(mdfeId: number) {
    return this.http.get(this.UrlService + `ManifestoTransporte/${mdfeId}`)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  cancelarMdfe(mdfeId: number, motivo: string) {
    const param = { mdfeId: mdfeId, motivo };
    return this.http.post(this.UrlService + 'ManifestoTransporte/Cancelar', param)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  encerrarMdfe(mdfeId: number, estado: number, cidade: number) {
    const param = { mdfeId, estado, cidade };
    return this.http.post(this.UrlService + 'ManifestoTransporte/Encerrar', param)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  imprimirMdfe(mdfeId: number, formato: any) {
    const params = { mdfeId, formato };

    return this.http
      .post(this.UrlService + 'ManifestoTransporte/Imprimir', params, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  previsualizarMdfe(dados) {
    return this.http.post(this.UrlService + 'ManifestoTransporte/PrevisualizarMdfe', dados, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  xmlMdfeCancelado(mdfeId) {
    return this.http
      .post(this.UrlService + 'ManifestoTransporte/XmlMdfeCancelado/', mdfeId, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  buscarEmailsMdfe() {
    return this.http.get(this.UrlService + 'ManifestoTransporte/BuscarEmailsMdfe')
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  enviarEmail(mdfeId: number, emails: string[]) {
    const dados = { mdfeId: mdfeId, emails };
    return this.http.post(this.UrlService + 'ManifestoTransporte/EnviarEmail', dados)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  abrirEnvioEmail(mdfeId: number) {
    if (mdfeId) {
      this.emailMdfe.next({ mdfeId: mdfeId });
    }
  }

  buscarDadosEncerramento(mdfeId: number) {
    return this.http.get(this.UrlService + `ManifestoTransporte/BuscarDadosEncerramento/${mdfeId}`)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  importarNfePropria(nfeIds: number[], chavesNfeReferenciadas: string[] = [], ufFim: string = null) {
    return this.http.post(this.UrlService + 'ManifestoTransporte/ImportarNfePropria',
      { ids: nfeIds, chavesNfeReferenciadas, ufFim })
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  importarCteProprio(cteIds: number[], chavesCteReferenciados: string[] = [], ufIni: string = null, ufFim: string = null) {
    return this.http.post(this.UrlService + 'ManifestoTransporte/ImportarCteProprio',
      { ids: cteIds, chavesCteReferenciados, ufIni, ufFim })
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  importarXmlNfe(dados: any) {
    return this.http
      .post(this.UrlService + 'ManifestoTransporte/ImportarXmlNfe', dados)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  importarXmlCte(dados: any) {
    return this.http
      .post(this.UrlService + 'ManifestoTransporte/ImportarXmlCte', dados)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }
}
