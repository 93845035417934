import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs';
import { StorageFunctions } from '../../../../general/storage';
import { fieldRequired, markFormGroupTouched, setFormValue } from '../../../../general/utils';
import { UtilFunctions } from '../../../../general/utils-service';
import { EstadosService } from '../../../../services/estados.service';
import { FornecedoresService } from '../../../../services/fornecedores.service';
import { MessageService } from '../../../../services/message.service';
import { VeiculosService } from '../../../../services/veiculos.service';
import { CadastroPadraoComponent } from '../../../../shared/cadastro-padrao/cadastro-padrao.component';
import swal from 'sweetalert2';

@Component({
  selector: 'app-veiculo-cadastro-form',
  templateUrl: './veiculo-cadastro-form.component.html',
  styleUrls: ['./veiculo-cadastro-form.component.scss']
})
export class VeiculoCadastroFormComponent extends CadastroPadraoComponent implements OnInit {
  @Input() modal: boolean = false;
  @Output() salvar = new EventEmitter<number>();
  @Output() cancelar = new EventEmitter();
  @Output() naoEncontrado = new EventEmitter();

  estadosMap = [];
  
  tipoVeiculo = [
    { id: 0, name: 'Tração' },
    { id: 1, name: 'Reboque' }
  ];

  tipoRodado = [
    { id: '01', name: 'Truck' },
    { id: '02', name: 'Toco' },
    { id: '03', name: 'Cavalo Mecânico' },
    { id: '04', name: 'VAN' },
    { id: '05', name: 'Utilitário' },
    { id: '06', name: 'Outros' },
  ];

  tipoCarroceria = [
    { id: '00', name: 'Não aplicável' },
    { id: '01', name: 'Aberta' },
    { id: '02', name: 'Fechada/Baú' },
    { id: '03', name: 'Granelera' },
    { id: '04', name: 'Porta Container' },
    { id: '05', name: 'Sider' },
  ];

  tipoProprietario = [
    { id: 'P', name: 'Próprio' },
    { id: 'T', name: 'Terceiro' },
  ];

  tipoPropTerceiro = [
    { id: '0', name: 'TAC Agregado' },
    { id: '1', name: 'TAC Independente' },
    { id: '2', name: 'Outros' },
  ];

  constructor(
    private estadoService: EstadosService,
    route: ActivatedRoute,
    utilFunctions: UtilFunctions,
    message: MessageService,
    storage: StorageFunctions,
    location: Location,
    private service: VeiculosService
  ) {
    super(route, utilFunctions, message, storage, location);

    this.form = new FormGroup({
      id: new FormControl(null),
      placa: new FormControl(null, [Validators.required, this.validarPlaca]),
      renavam: new FormControl(null, [Validators.required, Validators.minLength(9)]),
      uf: new FormControl(null, [Validators.required]),
      rntrc: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      capKG: new FormControl(null, [Validators.required]),
      capM3: new FormControl(null, [Validators.required]),
      tara: new FormControl(null, [Validators.required]),
      tpVeic: new FormControl(null, [Validators.required]),
      tpRod: new FormControl(null, [Validators.required]),
      tpCar: new FormControl(null, [Validators.required]),
      tpProp: new FormControl(null, [Validators.required]),
      tpPropTerc: new FormControl(null),
      fornecedorID: new FormControl(null),
    });
  }

  validarPlaca(control: FormControl): ValidationErrors | null {
    if (!control) {
      return null;
    }

    const placa = control.value;
    if (!/\w\w\w\d\d\d\d/.test(placa) && !/\w\w\w\d\w\d\d/.test(placa)) {
      return { custom: { message: 'Formato inválido' }};
    }

    return null;
  }

  ngOnInit(): void {
    this.carregarEstados();
    this.enableFieldsFornecedores();
  }

  enableFieldsFornecedores() {
    this.form.get('tpProp')?.valueChanges.subscribe((value) => {
      if (value === 'P') {
        this.form.get('tpPropTerc')?.disable();
        this.form.get('tpPropTerc')?.clearValidators();
        this.form.get('fornecedorID')?.disable();
        this.form.get('fornecedorID')?.clearValidators();
      } else {
        this.form.get('tpPropTerc')?.enable();
        this.form.get('tpPropTerc')?.setValidators([Validators.required]);
        this.form.get('fornecedorID')?.enable();
        this.form.get('fornecedorID')?.setValidators([Validators.required]);
      }

      this.form.get('tpPropTerc')?.updateValueAndValidity();
      this.form.get('fornecedorID')?.updateValueAndValidity();
    });
  }

  public carregarVeiculo(id: number) {
    this.alteraLoading(true);

    this.service.getCadastroById(id)
      .pipe(finalize(() => this.alteraLoading(false)))
      .subscribe(res => {
        if (res?.data === null) {
          this.messageService.error('Veículo não encontrado.', 5000);
          return;
        }
        setFormValue(this.form, res);
        this.form.controls['id'].setValue(id);
      })
  }

  definirPlaca(placa: string) {
    this.form.get('placa').setValue(placa?.toUpperCase());
  }

  submit() {
    markFormGroupTouched(this.form);
    if (!this.form.valid) {
      return;
    }

    this.loading['salvando'] = true;
    this.service.salvar(this.form.value)
      .pipe(finalize(() => {
        this.loading['salvando'] = false;
      }))
      .subscribe(res => {
        this.messageService.success('Veículo salvo com sucesso!');
        this.salvar.emit(res.generatedId);
      }, error => {
        this.messageService.error('Ocorreu um erro ao salvar o registro.');
      })
  }

  carregarEstados() {
    this.estadoService.get()
      .subscribe(res => {
        this.estadosMap = res;
      });
  }

  validaForm() {
    markFormGroupTouched(this.form);
  }

  fieldRequired(field: string) {
    return fieldRequired(this.form, field);
  }

  caixaAltaPlaca(formName) {
    const placaForm = this.form.get(formName);
    placaForm.setValue(placaForm.value?.toUpperCase());
  }

  get appendTo() {
    return this.modal ? '#modal-veiculo' : null;
  }

  excluir() {
    if (!this.form.get('id').value) {
      return;
    }
    swal({
      ...this.mensagemExclusao(),
      preConfirm: () => {
        return new Promise((resolve, reject) => {
          this.service.delete(this.form.get('id').value)
            .subscribe({
              next: () => {
                resolve({});
                this.operacaoRealizada();
              },
              error: (error) => {
                reject(error);
              }
            });
        })
        .catch((error) => {
          swal.showValidationError(`${error.msg.errors[0]}`);
        });
      },
      allowOutsideClick: () => !swal.isLoading()
    });
  }
}
