import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { MessageService } from './message.service';

@Injectable()
export class VeiculosService extends BaseService {

    constructor(private http: HttpClient, private message: MessageService) { super(); }

    get(consulta: any) {
        return this.http
            .get(this.UrlService + "veiculos/", consulta)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    getFornecedores() {
        return this.http
            .get(this.UrlService + "veiculos/getFornecedores")
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    salvar(data: any) {
        return this.http.post(this.UrlService + 'veiculos/', data)
            .pipe(map((res: any) => res.data),
                catchError(super.serviceError));
    }

    getCadastroById(id: number) {
        return this.http.get(this.UrlService + `Veiculos/getCadastroById/${id}`)
            .pipe(map(super.extractData)
                , catchError(super.serviceError))
    }

    getPaginadoCombobox(pagina: number, nome: string, tipoVeiculo: 'T' | 'R' | null) {
        return this.http
            .get(this.UrlService + "Veiculos/GetPaginadoCombobox?pageIndex=" + pagina + "&search=" + encodeURIComponent(nome)
                + "&tipoVeiculo=" + encodeURIComponent(tipoVeiculo))
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    getById(id: number) {
        return this.http
            .get(this.UrlService + `Veiculos/${id}`)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    delete(id: number) {
        return this.http
            .delete(this.UrlService + `Veiculos/${id}`)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }
}
