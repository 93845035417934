import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { MessageService } from '@services';
import { fileToBase64, getErrorMessage, markFormGroupTouched } from '@utils';
import { firstValueFrom } from 'rxjs';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { ManifestoTransporteService } from '../../../services/manifesto-transporte.service';

@Component({
  selector: 'app-mdfe-importar-xml-nfe',
  templateUrl: './mdfe-importar-xml-nfe.component.html',
  styleUrls: ['./mdfe-importar-xml-nfe.component.css']
})
export class MdfeImportarXmlNfeComponent extends ModalCadastroComponent implements OnInit {

  constructor(
    private mdfeService: ManifestoTransporteService,
    private messageService: MessageService,
  ) {
    super();
  }

  @ViewChild('modalConsultar', { static: true }) modalConsultar;
  maximoArquivos = 100;
  form = new FormGroup({
    files: new FormControl(null, [ FileUploadValidators.filesLimit(this.maximoArquivos), FileUploadValidators.accept(['.xml']) ]),
  });

  loading = {
    importarXmls: false,
  };

  passo: 'IMPORTAR' | 'ERROS' = 'IMPORTAR';
  chavesNfeReferenciadas = [];
  importacao = null;

  ngOnInit() {
    this.chavesNfeReferenciadas = this.prop.chavesNfeReferenciadas;
  }

  show() {
    this.modalConsultar.show();
  }

  async importarXmls() {
    markFormGroupTouched(this.form);
    const arquivos = this.form.get('files');
    if (this.hasErrors(arquivos)) {
      return;
    }

    try {
      this.loading.importarXmls = true;
      const req = {
        chavesNfeReferenciadas: this.chavesNfeReferenciadas,
        arquivos: [],
      };

      for (const file of arquivos.value) {
        const base64 = await fileToBase64(file);
        req.arquivos.push({
          nome: file.name,
          base64: base64,
        });
      }

      this.importacao = await firstValueFrom(this.mdfeService.importarXmlNfe(req));
      if (this.importacao?.erros.length === 0) {
        this.closed.emit(this.importacao);
      } else {
        this.loading.importarXmls = false;
        this.passo = 'ERROS';
      }
    } catch (err) {
      this.loading.importarXmls = false;
      if (getErrorMessage(err).includes('Request body too large.')) {
        this.messageService.error('Tamanho máximo de 20MB excedido.', 5000, '', false);
      } else {
        this.messageService.error(getErrorMessage(err), 8000);
      }
    }
  }

  hasErrors(arquivos): boolean {
    if (!arquivos.value) {
      this.messageService.error('Selecione pelo menos 1 arquivo!', 5000);
      return true;
    }

    if (arquivos.errors) {
      if (arquivos.errors.fileTypes) {
        this.messageService.error('Tipo de arquivo inválido. São aceitos somente arquivos .XML', 5000, '', false);
      } else if (arquivos.errors.filesLimit) {
        this.messageService.error('O máximo de arquivos é ' + this.maximoArquivos + '!', 5000, '', false);
      }

      return true;
    }

    const tamanhoTotal = arquivos.value.map(x => x.size).reduce((acc, x) => acc + x, 0);
    if (tamanhoTotal > 20*1024*1024) {
      this.messageService.error('Tamanho máximo de 20MB excedido.', 5000, '', false);
      return true;
    }

    return false;
  }

  arquivosImportados() {
    const quantidade = this.form.get('files').value === null ? 0 : this.form.get('files').value.length;
    return quantidade + ' / ' + this.maximoArquivos;
  }

  continuar() {
    this.closed.emit(this.importacao);
  }

  voltar() {
    this.passo = 'IMPORTAR';
  }
}
