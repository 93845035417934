<app-modal-basic #modal [hideHeader]='true' [hideFooter]='true' (closed)="onClose()">
  <div class="app-modal-body">
    <div class="row modalheader">
      <img class="img-responsive img-center" src="../../../../assets/images/modais/email.png" alt="">
      <span>Informe os e-mails para os quais deseja enviar o arquivo:</span>
    </div>
    <form [formGroup]="form">
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Destinatários</label>
          <app-email-box formControlName="emails" #emailBox></app-email-box>
        </div>
      </div>
    </form>
    <button type="button" (click)="enviar()" hiddenMenu class="btn btn-primary">
      <i class="ti ti-new-window"></i> Enviar
    </button>
    <button type="button" (click)="cancelar()" hiddenMenu class="btn btn-info">
      Cancelar
    </button>
  </div>
</app-modal-basic>
