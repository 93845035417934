<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="row">
    <div class="form-group col-6 col-lg-3">
      <label [class.label-required]="fieldRequired('placa')">Placa</label>
      <input type="text" placeholder="Digite a placa" class="form-control" formControlName="placa" maxlength="7"
        mask="SSS0A00" (blur)="caixaAltaPlaca('placa')" />
      <show-errors [control]="form.controls.placa"></show-errors>
    </div>
    <div class="form-group col-6 col-lg-3">
      <label [class.label-required]="fieldRequired('renavam')">Renavam</label>
      <input type="text" placeholder="Digite o Renavam" class="form-control" maxlength="11" mask="0{11}"
        formControlName="renavam" />
      <show-errors [control]="form.controls.renavam"></show-errors>
    </div>
    <div class="form-group col-6 col-lg-3">
      <label [class.label-required]="fieldRequired('uf')">Estado</label>
      <ng-select [appendTo]="appendTo" formControlName="uf" *ngIf="estadosMap" placeholder="Selecione o Estado"
        [items]="estadosMap" bindLabel="nome" bindValue="siglaEstado">
      </ng-select>
      <show-errors [control]="form.controls.uf"></show-errors>
    </div>
    <div class="form-group col-6 col-lg-3">
      <label [class.label-required]="fieldRequired('rntrc')">RNTRC</label>
      <input type="text" placeholder="Digite o RNTRC" class="form-control" maxlength="8" mask="0{8}"
        formControlName="rntrc" />
      <show-errors [control]="form.controls.rntrc"></show-errors>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-6 col-lg-4">
      <label [class.label-required]="fieldRequired('capKG')">Capacidade (kg)</label>
      <input type="text" id="forma_pagamento_descricao" placeholder="Informe a capacidade em kg" class="form-control"
        maxlength="6" mask="0{6}" formControlName="capKG" />
      <show-errors [control]="form.controls.capKG"></show-errors>
    </div>
    <div class="form-group col-6 col-lg-4">
      <label [class.label-required]="fieldRequired('capM3')">Capacidade (m³)</label>
      <input type="text" placeholder="Informe a capacidade em m³" class="form-control" maxlength="3" mask="0{3}"
        formControlName="capM3" />
      <show-errors [control]="form.controls.capM3"></show-errors>
    </div>
    <div class="form-group col-6 col-lg-4">
      <label [class.label-required]="fieldRequired('tara')">Tara (kg)</label>
      <input type="text" placeholder="Informe a tara" class="form-control" maxlength="6" mask="0{6}"
        formControlName="tara" />
      <show-errors [control]="form.controls.tara"></show-errors>
    </div>
    <div class="form-group col-6 col-lg-4">
      <label [class.label-required]="fieldRequired('tpVeic')">Tipo de Veículo</label>
      <ng-select [appendTo]="appendTo" [items]="tipoVeiculo" formControlName="tpVeic" placeholder="Selecione"
        bindLabel="name" [disableControl]='loading["carregando"]' bindValue="id">
      </ng-select>
      <show-errors [control]="form.controls.tpVeic"></show-errors>
    </div>
    <div class="form-group col-6 col-lg-4">
      <label [class.label-required]="fieldRequired('tpRod')">Tipo de Rodado</label>
      <ng-select [appendTo]="appendTo" [items]="tipoRodado" formControlName="tpRod" placeholder="Selecione"
        bindLabel="name" [disableControl]='loading["carregando"]' bindValue="id">
      </ng-select>
      <show-errors [control]="form.controls.tpRod"></show-errors>
    </div>
    <div class="form-group col-6 col-lg-4">
      <label [class.label-required]="fieldRequired('tpCar')">Tipo de Carroceria</label>
      <ng-select [appendTo]="appendTo" [items]="tipoCarroceria" formControlName="tpCar" placeholder="Selecione"
        bindLabel="name" [disableControl]='loading["carregando"]' bindValue="id">
      </ng-select>
      <show-errors [control]="form.controls.tpCar"></show-errors>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-6 col-lg-4">
      <label [class.label-required]="fieldRequired('tpProp')">Tipo de Proprietário</label>
      <ng-select [appendTo]="appendTo" [items]="tipoProprietario" formControlName="tpProp" placeholder="Selecione"
        bindLabel="name" [disableControl]='loading["carregando"]' bindValue="id">
      </ng-select>
      <show-errors [control]="form.controls.tpProp"></show-errors>
    </div>
    <div class="form-group col-6 col-lg-4">
      <label [class.label-required]="fieldRequired('tpPropTerc')">Tipo de Proprietário de Terceiro</label>
      <ng-select [appendTo]="appendTo" [items]="tipoPropTerceiro" formControlName="tpPropTerc"
        placeholder="Selecione" bindLabel="name" bindValue="id" [disableControl]='loading["carregando"]'>
      </ng-select>
      <show-errors [control]="form.controls.tpPropTerc"></show-errors>
    </div>
    <div class="form-group col-12 col-lg-4">
      <div rem5 combobox [type]="'fornecedor'" formControlName="fornecedorID" [label]="'Proprietário'"
        [form]="form" [appendTo]="appendTo" [modalCadastro]="true">
      </div>
    </div>
  </div>

  <div class="form-button-row">

    <button class="btn btn-primary custom-form-button submit-form" type="submit" (click)="validaForm()"
      [disabled]="loading['registro'] || loading['salvando']">
      <app-loading [loading]="loading['salvando']" texto="Salvar" textoLoading="Salvando" icone="ti-save"></app-loading>
    </button>
    <button *ngIf="!modal && form.get('id').value" class="btn btn-danger custom-form-button"
      [disabled]="loading['registro'] || loading['salvando']"
      (click)="excluir()" type="button">
      <i class="ti ti-trash"></i> Excluir
    </button>
    <button class="btn btn-info custom-form-button" type="button" [disabled]="loading['salvando']" (click)="cancelar.emit()">
      <i class="ti ti-back-left"></i> Voltar
    </button>

  </div>
</form>