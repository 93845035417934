<app-temp-modal-cadastro #modalConsultar textHeader="Importação CT-e Próprio" (closed)="onClose()"
  customClass="modal-lg" [modalContentId]="'modal-mdfe-importar-cte-proprio'">
  <div class="app-modal-body">
    <ng-container *ngIf="passo === 'IMPORTAR'">
      <form [formGroup]="form">
        <div class="row">
          <div class="form-group col-sm-12">
            <label for="modal-mdfe-importar-cte-search">Pesquisar</label>
            <input id="modal-mdfe-importar-cte-search" type="text" class="form-control" formControlName="search"
              placeholder="Pesquisar">
          </div>
        </div>
      </form>

      <h4 *ngIf="!ctes" class="text-center"><i class="fa fa-circle-o-notch fa-spin"></i></h4>
      <ng-container *ngIf="ctes">
        <app-dim-datatable [loading]="loading.consulta" [totalItems]="10" [data]="ctes.items"
          [columns]="columns" [selectable]="true" [selecionados]="ctesSelecionados" (sorting)="onSortNotas($event)"
          [showPagination]="false">
        </app-dim-datatable>
      </ng-container>

      <button class="btn btn-primary" (click)="importar()" [disabled]="ctesSelecionados.length === 0 || loading.importar">
        <app-loading [loading]="loading.importar" texto="Importar"></app-loading>
      </button>
    </ng-container>

    <div *ngIf="passo === 'ERROS'">
      <div class="alert alert-danger text-danger">
        A importação retornou erros. Se continuar esses documentos serão ignorados.
      </div>

      <ul>
        <li *ngFor="let erro of importacao.erros" class="mt-3">
          {{ erro.mensagem }}
        </li>
      </ul>

      <div class="text-center m-t-20">
        <button class="btn btn-primary" (click)="continuar()">
          Continuar
        </button>
        <button class="btn btn-info" (click)="voltar()">
          Voltar
        </button>
      </div>
    </div>
  </div>

  <div class="app-modal-footer">
  </div>
</app-temp-modal-cadastro>