import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { MotoristaCadastroFormComponent } from './motorista-cadastro-form/motorista-cadastro-form.component';

@Component({
  selector: 'app-motorista',
  templateUrl: './motorista.component.html',
  styleUrls: ['./motorista.component.css']
})
export class MotoristaComponent extends ModalCadastroComponent {
  @ViewChild('modalCadastro', { static: true }) modalCadastro;
  @ViewChild('motoristaForm', { static: true }) motoristaForm: MotoristaCadastroFormComponent;
  @Output() closed: EventEmitter<any> = new EventEmitter();

  editar(id: number) {
    this.id = id;
    this.motoristaForm.carregarMotorista(id);
    this.modalCadastro.show();
  }

  cadastrar(searchParam) {
    this.id = null;
    this.motoristaForm.definirNome(searchParam);
    this.modalCadastro.show();
  }

  onSalvar(id: number) {
    this.id = id;
    this.modalCadastro.hide();
  }

  onCancelar() {
    this.modalCadastro.hide();
  }
}
