import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { MessageService } from './message.service';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ConhecimentoTransporteService extends BaseService {

  private emailCte = new Subject<{ cteId: number }>();
  solicitacaoAbrirEnvioEmail = this.emailCte.asObservable();

  constructor(private http: HttpClient, private message: MessageService) { super(); }

  consultar(filtros) {
    return this.http.post(this.UrlService + 'ConhecimentoTransporte/Consultar', filtros)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  salvar(dados): Observable<number> {
    return this.http.post(this.UrlService + 'ConhecimentoTransporte/Salvar', dados)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  salvarRascunho(dados): Observable<number> {
    return this.http.post(this.UrlService + 'ConhecimentoTransporte/SalvarRascunho', dados)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  enviar(cteId: number): Observable<{ cteId: number, cteNum: number }> {
    return this.http.post(this.UrlService + 'ConhecimentoTransporte/Enviar', cteId)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  buscarDadosCte(cteId: number) {
    return this.http.get(this.UrlService + `ConhecimentoTransporte/${cteId}`)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  cancelarCte(cteId: number, motivo: string) {
    const param = { cteId, motivo };
    return this.http.post(this.UrlService + 'ConhecimentoTransporte/cancelar', param)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  imprimirCte(cteId: number, formato: any) {
    const params = {
      cteId: cteId,
      formato: formato
    };

    return this.http
      .post(this.UrlService + 'ConhecimentoTransporte/imprimirCte', params, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  previsualizarCte(dados) {
    return this.http.post(this.UrlService + 'ConhecimentoTransporte/PrevisualizarCte', dados, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  importarNfePropria(nfeIds: number[], chavesNfeReferenciadas: string[] = []) {
    return this.http.post(this.UrlService + 'ConhecimentoTransporte/ImportarNfePropria',
      { ids: nfeIds, chavesNfeReferenciadas })
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  importarXmlNfe(dados: any) {
    return this.http
      .post(this.UrlService + 'ConhecimentoTransporte/ImportarXmlNfe', dados)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  importarXmlCte(dados: any) {
    return this.http
      .post(this.UrlService + 'ConhecimentoTransporte/ImportarXmlCte', dados)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  buscarEmailsCTe(cteId) {
    return this.http.get(this.UrlService + `ConhecimentoTransporte/BuscarEmailsCTe/${cteId}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  enviarEmail(cteId: number, emails: string[]) {
    const dados = { cteId, emails };
    return this.http.post(this.UrlService + 'ConhecimentoTransporte/EnviarEmail', dados)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  abrirEnvioEmail(cteId: number) {
    if (cteId) {
      this.emailCte.next({ cteId });
    }
  }

  xmlCteCancelado(cteId) {
    return this.http
      .post(this.UrlService + 'ConhecimentoTransporte/XmlCteCancelado/', cteId, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  cartaCorrecao(dados: any) {
    return this.http.post(this.UrlService + 'ConhecimentoTransporte/GerarCartaCorrecao', dados)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  imprimirCCe(cteId: number, formato: string) {
    const params = {
      cteId: cteId,
      formato: formato
    };
    return this.http
      .post(this.UrlService + 'ConhecimentoTransporte/ImprimirCce', params)
      .pipe(map((res: any) => res.data));
  }

  listarCte(consulta) {
    return this.http.post(this.UrlService + 'ConhecimentoTransporte/ListarCte', consulta)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }
}
