import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from '@microsoft/signalr';
import { MessageService } from '@services';
import { getErrorMessage } from '@utils';
import { firstValueFrom } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';
import { ParamConsulta } from '../../../general/models/paramConsulta';
import { ManifestoTransporteService } from '../../../services/manifesto-transporte.service';
import { NotasFiscaisService } from '../../../services/notas-fiscais.service';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';

@Component({
  selector: 'app-mdfe-importar-nfe-propria',
  templateUrl: './mdfe-importar-nfe-propria.component.html',
  styleUrls: ['./mdfe-importar-nfe-propria.component.css']
})
export class MdfeImportarNfePropriaComponent extends ModalCadastroComponent implements OnInit {

  constructor(
    private notasFiscaisService: NotasFiscaisService,
    private mdfeService: ManifestoTransporteService,
    private messageService: MessageService,
  ) {
    super();
  }

  @ViewChild('modalConsultar', { static: true }) modalConsultar;
  $searchChanged: Subject<string> = new Subject();
  form: FormGroup;
  notasFiscais;
  notasSelecionadas = [];
  columns = [
    { label: 'Número', col: 'numeroNotaFiscal', hide: false },
    { label: 'Cliente', col: 'cliente', hide: false, limit: 30 },
    { label: 'UF Dest.', col: 'uf', hide: false },
    { label: 'Emissão', col: 'emissao', date: true, hide: false },
    { label: 'Valor', col: 'valorTotal', hide: false, currency: true },
    { label: 'Quantidade', col: 'quantidade', hide: false },
  ];
  importacao = null;
  chavesNfeReferenciadas = [];
  ufFim = null;
  passo: 'IMPORTAR' | 'ERROS' = 'IMPORTAR';
  loading = {
    consulta: false,
    importar: false,
  };

  ngOnInit() {
    this.chavesNfeReferenciadas = this.prop.chavesNfeReferenciadas;
    this.ufFim = this.prop.ufFim;

    this.form = new FormGroup({
      search: new FormControl(null),
      param: new FormControl(new ParamConsulta()),
    });

    this.form.get('search').valueChanges.pipe(debounceTime(800)).subscribe(() => {
      if (!this.loading.consulta) {
        this.consultar(true);
      }
    });

    this.consultar(true);
  }

  show() {
    this.modalConsultar.show();
  }

  onSortNotas(ev) {
    this.form.get('param').setValue(ev);
    this.consultar(false);
  }

  consultar(resetarOrdenacao: boolean) {
    this.loading.consulta = true;

    if (resetarOrdenacao) {
      const param = new ParamConsulta();
      param.alterarOrdenacao('emissao', 'desc');
      this.form.get('param').setValue(param);
    }

    this.notasFiscaisService.listarNotasFiscais(this.form.value)
      .pipe(finalize(() => this.loading.consulta = false))
      .subscribe(res => {
        if (res) {
          this.notasFiscais = res;
        }
      });
  }

  async importar() {
    try {
      this.loading.importar = true;

      const ids = this.notasSelecionadas.map(x => x.id);
      this.importacao = await firstValueFrom(
        this.mdfeService.importarNfePropria(ids, this.chavesNfeReferenciadas, this.ufFim));

      if (this.importacao.erros?.length > 0) {
        this.loading.importar = false;
        this.passo = 'ERROS';
      } else {
        this.closed.emit(this.importacao);
      }
    } catch (err) {
      this.messageService.error(getErrorMessage(err), 8000);
      this.loading.importar = false;
    }
  }

  continuar() {
    this.closed.emit(this.importacao);
  }

  voltar() {
    this.passo = 'IMPORTAR';
  }
}
