import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';
import { NfConsumidorService } from '../../../../services/nf-consumidor.service';
import { MessageService } from '../../../../services/message.service';
import { ModalBasicComponent } from '../../../../shared/modal-basic/modal-basic.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { getErrorMessage, markFormGroupTouched } from '@utils';
import { lastValueFrom } from 'rxjs';
import * as saveAs from 'file-saver';

@Component({
  selector: 'app-venda-nfce',
  templateUrl: './venda-nfce.component.html',
  styleUrls: ['./venda-nfce.component.css']
})
export class VendaNfceComponent extends ModalCadastroComponent implements OnInit {
  
  @ViewChild('modalRelatorio', { static: true }) modalRelatorio: ModalBasicComponent;
  impressaoForm: FormGroup;
  constructor(
  private nfConsumidorService: NfConsumidorService, 
  private message: MessageService) 
  { super() }
  
  ngOnInit() {
    this.impressaoForm = new FormGroup({
      periodo1: new FormControl(moment().startOf('month').toDate(), [Validators.required]),
      periodo2: new FormControl(moment().endOf('month').startOf('day').toDate(), [Validators.required]),
      formaPagamento: new FormControl(false),
    })
  }

  destroyComponent() {
    this.closed.emit();
  }

  show(): void {
    this.modalRelatorio.show();
  }

  validaForm() {
    markFormGroupTouched(this.impressaoForm);
  }

  async submitForm() {
    if (this.impressaoForm.valid) {
      this.loading = true;
      this.modalRelatorio.hide();
      
      try {
        const res = await lastValueFrom(this.nfConsumidorService.vendaNfce(this.impressaoForm.value));
        saveAs(res, 'VendasNFCe.pdf');
      } catch (error) {
        console.error(error);
        this.message.addError({
          title: 'Falha ao gerar relatório.',
          msg: 'Nenhum registro foi encontrado na data informada',
          showClose: true,
          timeout: 20000,
          theme: 'bootstrap',
          closeOther: true
        });
      } finally {
        this.loading = false;
        this.destroyComponent();
      }
    }
  }
 }
