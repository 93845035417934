<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="row">
    <div class="form-group col-12 col-md-4">
      <label><strong>Nome</strong></label>
      <input type="text" placeholder="Informe o Nome" class="form-control" formControlName="nome" maxlength="60" />
      <show-errors [control]="form.controls.nome"></show-errors>
    </div>
    <div class="form-group col-6 col-md-4">
      <label>Apelido</label>
      <input type="text" placeholder="Informe o Apelido" class="form-control" maxlength="60"
        formControlName="apelido" />
    </div>
    <div class="form-group col-6 col-md-4">
      <label>Data Nascimento</label>
      <app-data [disableControl]="loading['disable']" formControlName="dataAniversario"></app-data>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-6 col-md-4">
      <label><strong>CPF</strong></label>
      <input type="text" placeholder="Informe o CPF" class="form-control" formControlName="cpf" mask="000.000.000-00" />
      <show-errors [control]="form.controls.cpf"></show-errors>
    </div>

    <div class="form-group col-6 col-md-4">
      <label>RG</label>
      <input type="text" placeholder="Informe o RG" class="form-control" formControlName="rg" maxlength="18" />
    </div>

    <div class="form-group col-6 col-md-4">
      <label>PIS</label>
      <input type="text" placeholder="Informe o Número PIS" class="form-control" formControlName="codigoPIS"
        maxlength="20" />
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <h4 class="sub-title">Endereço e Contato</h4>

      <div class="row">
        <div class="form-group col-6 col-md-3">
          <label>CEP</label>
          <input type="text" placeholder="Informe o CEP" class="form-control" formControlName="cep" mask="00000-000" />
          <show-errors [control]="form.controls.cep"></show-errors>
        </div>

        <div class="form-group col-12 col-md-5">
          <label>Endereço</label>
          <input type="text" placeholder="Informe o Endereço" class="form-control" formControlName="endereco"
            maxlength="60" />
        </div>

        <div class="form-group col-12 col-md-4">
          <label>Bairro</label>
          <input type="text" placeholder="Informe o Bairro" class="form-control" formControlName="bairro"
            maxlength="60" />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-4 col-md-3">
          <label><strong>Estado</strong></label>
          <ng-select [appendTo]="appendTo" formControlName="estadoId" placeholder="Selecione" [items]="estadosMap"
            bindLabel="siglaEstado" bindValue="id" [disableControl]="loading['request'] || loading['disable']">
          </ng-select>
          <show-errors [control]="form.controls.estadoId"></show-errors>
        </div>

        <div class="form-group col-8 col-md-5">
          <label><strong>Cidade</strong></label>
          <ng-select [appendTo]="appendTo" formControlName="cidadeId" placeholder="Selecione" [items]="cidadesMap"
            bindLabel="nome" [disableControl]="loading['request'] || loading['disable']" bindValue="id">
          </ng-select>
          <show-errors [control]="form.controls.cidadeId"></show-errors>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-12 col-md-3">
          <label>Contato</label>
          <input type="text" placeholder="Informe o Contato" class="form-control" formControlName="contato"
            maxlength="60" />
        </div>

        <div class="form-group col-12 col-md-9">
          <label>E-mail</label>
          <input type="text" placeholder="Informe o E-mail" class="form-control" formControlName="email"
            maxlength="2000" />
        </div>

        <div class="form-group col-6 col-md-3">
          <label>Telefone</label>
          <input type="text" placeholder="Informe o Telefone" class="form-control" formControlName="telefone"
            maxlength="20" mask="(00) 0000-0000" />
          <show-errors [control]="form.controls.telefone"></show-errors>
        </div>

        <div class="form-group col-6 col-md-3">
          <label>Celular</label>
          <input type="text" placeholder="Informe o Celular" class="form-control" formControlName="celular"
            maxlength="20" mask="(00) 0 0000-0000" />
          <show-errors [control]="form.controls.celular"></show-errors>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <h4 class="sub-title">Outras informações</h4>

      <div class="row">
        <div class="form-group col-6" rem5 combobox [type]="'veiculo'" formControlName="veiculoPadraoID"
          [label]="'Veículo Padrão'" [form]="form" [appendTo]="appendTo">
        </div>

        <div class="form-group col-6" rem5 combobox [type]="'fornecedor'" formControlName="fornecedorID"
          [label]="'Fornecedor'" [form]="form" [appendTo]="appendTo">
        </div>
      </div>

      <div class="row">
        <div class="form-group col-12">
          <label>Observações</label>
          <textarea [disableControl]="loading['registro']" rows="5" class="form-control" formControlName="observacoes"
            maxlength="4000">
          </textarea>
        </div>
      </div>
    </div>
  </div>

  <div class="form-button-row">
    <button class="btn btn-primary custom-form-button submit-form" type="submit" (click)="validaForm()"
      [disabled]="loading['salvando']">
      <app-loading [loading]="loading['salvando']" texto="Salvar" textoLoading="Salvando" icone="ti-save"></app-loading>
    </button>
    <button *ngIf="!modal && form.get('id').value" class="btn btn-danger custom-form-button"
      [disabled]="loading['registro'] || loading['salvando']"
      (click)="excluir()" type="button">
      <i class="ti ti-trash"></i> Excluir
    </button>
    <button class="btn btn-info custom-form-button" (click)="cancelar.emit()" type="button" [disabled]="loading['salvando']">
      <i class="ti ti-back-left"></i> Voltar
    </button>
  </div>
</form>