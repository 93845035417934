export class TipoNfe {
    public static VENDA = 1;
    public static DEVOLUCAO = 3;
    public static VENDA_ORIGINADA_ENCOMENDA_FUTURA = 24;
    public static OUTRAS_SAIDAS = 25;
    public static NFE_COMPLEMENTAR = 28;
    public static VENDA_A_ORDEM = 30; // 5120/6120
    public static RETORNO_REMESSA_ATIVO_IMOBILIZADO = 31; // 5554/6554
    public static IMPORTACAO = 34; // 3101/3102/3551/3949
    public static EXPORTACAO = 36; // 7XXX
    public static NOTA_FILHA = 37;
    public static NOTA_SAIDA_IMPORTACAO = 38;
    public static TRANSFERENCIA_MATERIAL_USO_CONSUMO = 39; // 5557/6557
    public static NOTA_DEVOLUCAO_VENDA = 40;
    public static NF_REMESSA_RETORNO_MERCADORIA_EXPOSICAO_FEIRA = 35;
    public static NFE_COM_CFOP_INFORMADA = 25; // (5949, 6949 e outros) c/CFOP informada, impostos p/NCM e opção de clonagem
}
