import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-dim-radio-group',
  templateUrl: './dim-radio-group.component.html',
  styleUrls: ['./dim-radio-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DimRadioGroupComponent),
      multi: true
    }
  ]
})
export class DimRadioGroupComponent implements ControlValueAccessor {

  @Input() id: string = null;
  @Input() label = 'Radio group';
  @Input() options: DimRadioGroupOptionsViewModel[];

  selected = new FormControl<string>(null);

  onChange: (value: string) => string;
  onTouched: () => void;

  writeValue(value: string) {
    this.selected.setValue(value);
  }

  registerOnChange(changed: (value: string) => string): void {
    this.onChange = changed;
  }

  registerOnTouched(touched: () => void): void {
    this.onTouched = touched;
  }

  handleOnChange() {
    this.onChange(this.selected.value);
  }

}

export interface DimRadioGroupOptionsViewModel {
  label: string;
  value: string | number;
  icon?: string;
  style?: DimRadioGroupOptionsStyleViewModel;
  types?: number[]; // Utilizar para filtrar as opções conforme regra desejada.
}

interface DimRadioGroupOptionsStyleViewModel {
  'margin-bottom': string;
  'cursor': 'pointer';
}
