<app-temp-modal-cadastro #modalCadastro (closed)="onClose()" [textHeader]="'Importar XML CT-e'"
  [customClass]="passo === 'ERROS' ? 'modal-lg' : null">
  <form [formGroup]="form" *ngIf="passo === 'IMPORTAR'">
    <file-upload formControlName="files" accept=".xml">
      <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>
        <div class="row div-upload">
          <div class="col-sm-3 div-upload-icon"><i class="fa fa-upload upload-icon"></i></div>
          <div class="col-sm-9" *ngIf="!form.get('files').value">
            <span><b>Arraste e solte arquivos</b> ou clique aqui</span>
          </div>
        </div>
      </ng-template>
    </file-upload>
    <div class="text-center m-t-20">
      <button [disabled]="loading.importarXmls" class="btn btn-primary" (click)="importarXmls()">
        <app-loading texto="Importar" [loading]="loading.importarXmls"
          [icone]="'icofont icofont-refresh'"></app-loading>
      </button>
      <span>{{arquivosImportados()}}</span>
    </div>
  </form>

  <div *ngIf="passo === 'ERROS'">
    <div class="alert alert-danger text-danger">
      A importação retornou erros. Se continuar esses arquivos serão ignorados.
    </div>

    <ul>
      <li *ngFor="let erro of importacao.erros" class="mt-3">
        <strong>{{ erro.nome }}: </strong>{{ erro.mensagem }}
      </li>
    </ul>

    <div class="text-center m-t-20">
      <button class="btn btn-primary" (click)="continuar()">
        Continuar
      </button>
      <button class="btn btn-info" (click)="voltar()">
        Voltar
      </button>
    </div>
  </div>
</app-temp-modal-cadastro>