import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { catchError, map } from 'rxjs';

@Injectable()
export class MotoristasService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getPaginadoCombobox(pagina: number, nome: string) {
    return this.http
      .get(this.UrlService + "Motoristas/GetPaginadoCombobox?pageIndex=" + pagina + "&search=" + encodeURIComponent(nome))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getById(id: number) {
    return this.http
      .get(this.UrlService + `Motoristas/${id}`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getAll(consulta: any) {
    return this.http
      .get(this.UrlService + "motoristas/", consulta)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  salvar(data: any) {
    return this.http.post(this.UrlService + 'motoristas/', data)
      .pipe(map((res: any) => res.data),
        catchError(super.serviceError));
  }

  delete(id: number) {
    return this.http
      .delete(this.UrlService + `motoristas/${id}`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}
