<app-modal-basic #modal [hideHeader]='true' [hideFooter]='true' (closed)="onClose()"
  dialogClass="modal-dialog-centered" modalContentId="modal-mdfe-encerramento">
  <div class="app-modal-body">
    <div class="row">
      <div class="col">
        <h4 style="text-align: center">Encerrar MDF-e</h4>
      </div>
    </div>

    <form [formGroup]="form" class="mt-4">
      <div class="row">
        <div class="col">
          <p>Selecione a cidade de encerramento do MDF-e:</p>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-4">
          <label class="label-required">Estado</label>
          <ng-select appendTo="#modal-mdfe-encerramento" formControlName="estado" placeholder="Selecione" [items]="estados"
            bindLabel="siglaEstado" bindValue="id" (ngModelChange)="onChangeEstado($event)" [clearable]="false"
            [loading]="loading['dados'] || loading['estados']" [disableControl]="loading['dados'] || loading['estados']">
          </ng-select>
          <show-errors [control]="form.controls.estado"></show-errors>
        </div>
        <div class="form-group col">
          <label class="label-required">Cidade</label>
          <ng-select appendTo="#modal-mdfe-encerramento" formControlName="cidade" placeholder="Selecione"
            [items]="cidades" bindLabel="nome" bindValue="id" [clearable]="false"
            [loading]="loading['dados'] || loading['cidades']" [disableControl]="loading['dados'] || loading['cidades']">
          </ng-select>
          <show-errors [control]="form.controls.cidade"></show-errors>
        </div>
      </div>
    </form>

    <div class="row mt-4">
      <div class="col">
        <button type="button" (click)="encerrar()" hiddenMenu class="btn btn-primary"
          [disabled]="loading['encerrar'] || loading['dados'] || loading['cidades'] || loading['estados']">
          <app-loading icone="ti ti-new-window" [loading]="loading['encerrar']" texto="Encerrar"
            textoLoading="Enviando... Aguarde!"></app-loading>
        </button>
    
        <button type="button" (click)="voltar()" hiddenMenu class="btn btn-info"
          [disabled]="loading['encerrar']">
          Voltar
        </button>
      </div>
    </div>
  </div>
</app-modal-basic>