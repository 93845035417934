import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { getErrorMessage, markFormGroupTouched } from '../../../general/utils';
import { CidadesService } from '../../../services/cidades.service';
import { EstadosService } from '../../../services/estados.service';
import { ManifestoTransporteService } from '../../../services/manifesto-transporte.service';
import { MessageService } from '../../../services/message.service';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';

@Component({
  selector: 'app-mdfe-encerramento',
  templateUrl: './mdfe-encerramento.component.html',
  styleUrls: ['./mdfe-encerramento.component.css']
})

export class MdfeEncerramentoComponent extends ModalCadastroComponent implements OnInit {
  @ViewChild('modal', { static: true }) modalConsultar;
  estados = [];
  cidades = [];
  mdfeId: number;

  form: FormGroup = new FormGroup({
    estado: new FormControl(null, [Validators.required]),
    cidade: new FormControl(null, [Validators.required]),
  });

  constructor(
    private mdfeService: ManifestoTransporteService,
    private estadosService: EstadosService,
    private cidadesService: CidadesService,
    public messageService: MessageService,
  ) {
    super();
  }

  show() {
    this.modalConsultar.show();
  }

  onClose() {
    this.closed.emit();
  }

  async encerrar() {
    markFormGroupTouched(this.form);
    if (!this.form.valid) {
      return;
    }

    try {
      this.loading.encerrar = true;
      this.messageService.clearAll();
      
      const formData = this.form.getRawValue();
      await firstValueFrom(this.mdfeService.encerrarMdfe(this.mdfeId, formData.estado, formData.cidade));

      this.messageService.success('MDF-e encerrado com sucesso!', 5000);
      this.closed.emit();
    } catch (err) {
      this.messageService.error(getErrorMessage(err), 8000);
      this.closed.emit();
    } finally {
      this.loading.encerrar = false;
    }
  }

  voltar() {
    this.closed.emit();
  }

  ngOnInit() {
    this.mdfeId = this.prop.mdfeId;

    this.loading['estados'] = true;
    this.estadosService.get().subscribe(res => {
      this.estados = res;
      this.loading['estados'] = false;
    }, error => {
      this.loading['estados'] = false;
    });

    this.loading['dados'] = true;
    this.mdfeService.buscarDadosEncerramento(this.mdfeId).subscribe(res => {
      this.form.get('estado').setValue(res.estado);
      this.form.get('cidade').setValue(res.cidade);
      this.loading['dados'] = false;
    }, error => {
      this.loading['dados'] = false;
    });
  }

  onChangeEstado(ev) {
    this.loading['cidades'] = true;
    this.cidadesService.getByEstado(ev).subscribe(res => {
      this.cidades = res;

      const cidadeAtual = this.form.get('cidade').value;
      if (cidadeAtual && !this.cidades.find(x => x.id === cidadeAtual)) {
        this.form.get('cidade').setValue(null);
      }
      this.loading['cidades'] = false;
    }, error => {
      this.loading['cidades'] = false;
    });
  }
}
