import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { VeiculoCadastroFormComponent } from './veiculo-cadastro-form/veiculo-cadastro-form.component';

@Component({
  selector: 'app-veiculo',
  templateUrl: './veiculo.component.html',
  styleUrls: ['./veiculo.component.css']
})
export class VeiculoComponent extends ModalCadastroComponent {
  @ViewChild('modalCadastro', { static: true }) modalCadastro;
  @ViewChild('veiculoForm', { static: true }) veiculoForm: VeiculoCadastroFormComponent;
  @Output() closed: EventEmitter<any> = new EventEmitter();

  editar(id: number) {
    this.id = id;
    this.veiculoForm.carregarVeiculo(id);
    this.modalCadastro.show();
  }

  cadastrar(searchParam) {
    this.id = null;
    this.veiculoForm.definirPlaca(searchParam);
    this.modalCadastro.show();
  }

  onSalvar(id: number) {
    this.id = id;
    this.modalCadastro.hide();
  }

  onCancelar() {
    this.modalCadastro.hide();
  }
}
